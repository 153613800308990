<template>
    <div>
        <hr>
        <label for="intra_location_choice">{{ $t("intra_location.deplacement_interne") | capitalize }}</label>

		<e-select
			id="intra_location_choice"
			class="form-group"
			ref="intra_location_choice"
			track-by="uuid"
			label="intralocation_label"
			:placeholder="$t('intra_location.rechercher_localisation')"
			:selectedLabel="$t('global.selected_label')"
			:options="intra_locations"
			:searchable="true"
			:allow-empty="true"
			v-model="sel_intra_location"
			@input="onChange"
		>
			<template slot="option" slot-scope="props"><span :style="`padding-left: ${25 * (props.option.indent || 0) }px;`">{{ props.option.intralocation_label }}</span></template>
		</e-select>

		<div class="form-group" v-if="sel_intra_location">
			<label>{{ $t("intra_location.raison_deplacement") }}</label>
			<input class="form-control" type="text" id="intra_mouvement_reason" :placeholder="$t('intra_location.raison_deplacement')" v-model="sel_intra_location.raison">
		</div>
    </div>
</template>

<script type="text/javascript">
import IntraLocation from '@/mixins/IntraLocation'

export default {
	name: "AskIntraLocation",
    mixins: [IntraLocation],
    props: {
		selected_location: { type: Object, default: null },
		default_location_id: { type: Number, default: 0 },
		residence_id: { type: Number, default: 0}
    },
    data() {
        return {
			intra_locations: [],
			sel_intra_location: null,
			working: false,
			residence_id_changed: false
        }
	},
	created() {
		this.preinit_component()
	},
    methods: {
        async preinit_component() {
			this.loadLocations()

			if(this.selected_location) {
				this.sel_intra_location = this.selected_location
			}
		},
		async loadLocations() {
			if(this.working) return
			this.working = true

			if(this.residence_id > 0) {
				this.intra_locations = await this.getResidenceIntraLocations(this.residence_id)
			}
			else {
				this.intra_locations = await this.getIntraLocationsWithresidences(false, true)
			}

			if(this.default_location_id != 0) {
				this.sel_intra_location = this.intra_locations.find(il => il.intralocation_id == this.default_location_id)
				this.onChange(this.sel_intra_location)
			}

			this.working = false

			// Si entre temps on a eu un changement de residence_id, on reload
			if(this.residence_id_changed) {
				this.residence_id_changed = false
				return this.loadLocations()
			} 
		},
		onChange(val) {
			if(!val) {
				val = {}
			}

			// On récupère la raison, si on appelle onChange de loadLocations par ex
			if(this.selected_location && this.selected_location.raison && !val.raison) {
				val.raison = this.selected_location.raison
			}

			this.$emit('update:selected_location', val)
		}
	},
	watch: {
		residence_id(val) {
			if(!this.working) {
				return this.loadLocations()
			}

			// Si le chargement est déjà en cours, pour ne pas casser la réactivité, on attend pour relancer le chargement
			this.residence_id_changed = true
		},
		default_location_id(val) {
			this.sel_intra_location = this.intra_locations.find(il => il.intralocation_id == val)
			this.onChange(this.sel_intra_location)
		}
	}
}
</script>
